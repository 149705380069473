import React, { useEffect } from 'react'
import Layout from 'layouts'

import useUIContext from 'context/ui'

import NoSignal from './components/NoSignal/NoSignal'
import SorryFooter from './components/SorryFooter/SorryFooter'

const NotFoundPage = () => {
  const setDarkLogo = useUIContext((s) => s.setDarkLogo)
  useEffect(() => {
    setDarkLogo(true)
    return () => {
      setDarkLogo(false)
    }
  }, [])
  return (
    <Layout footer={null}>
      <NoSignal />
      <SorryFooter />
    </Layout>
  )
}

export default NotFoundPage
