import React, { useState, useEffect } from 'react'
import classNames from 'classnames/bind'

import { Headline4, BodyCopy } from 'components/ui/Typography'
import Button from 'components/ui/Button'

import s from './SorryFooter.module.scss'

const cn = classNames.bind(s)

const SorryFooter = () => {
  const [seconds, setSeconds] = useState(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])
  const getSeconds = () => {
    const s = seconds % 60
    return s < 10 ? `0${s}` : s
  }
  const getMinutes = () => {
    const m = seconds > 60 ? Math.floor(seconds / 60) % 60 : 0
    return m < 10 ? `0${m}` : m
  }
  return (
    <div className={cn('container')}>
      <Headline4 className={cn('hideOnMobile')}>4 - 0 - 4</Headline4>
      <BodyCopy className={cn('bodyCopy')}>
        Sorry we lost your page. <Button to={'/'}>Back to home</Button>
      </BodyCopy>
      <div className={cn('timer', 'hideOnMobile')}>
        <Headline4 className={cn('timerHeadline')}>
          {getMinutes()}:{getSeconds()}
        </Headline4>
      </div>
    </div>
  )
}

export default SorryFooter
